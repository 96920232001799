@import url("https://fonts.googleapis.com/css?family=Montserrat");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 25px;
  height: 13px;
  padding: 0;
  border-radius: 30px;
  /* border: 0.5px solid #4D4D4D; */
  background-color: #c5c5c5;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  /* background-color: #19AB27; */
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  /* background-color: #128D15; */
}

.react-toggle-track-check {
  /* border: 1px solid red; */
  position: absolute;
  width: 12px;
  height: 12px;
  top: 1px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 3px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 12px;
  height: 12px;
  top: 2px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 4px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  -moz-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1.5px;
  left: 1px;
  width: 10px;
  height: 10px;
  border: 1px solid #959595;
  border-radius: 50%;
  background-color: #FAFAFA;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 13px;
  border: none
}

.react-toggle--focus .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
  -moz-box-shadow: 0px 0px 3px 2px #0099E0;
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
  -moz-box-shadow: 0px 0px 5px 5px #0099E0;
  box-shadow: 0px 0px 5px 5px #0099E0;
}

.custom.react-toggle--checked .react-toggle-track {
  background-color: #6e21b6;
}

@keyframes bounce-5 {
  0% {
    transform: translateY(0);
  }

  10% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-35px);
  }

  50% {
    transform: translateY(0);
  }

  57% {
    transform: translateY(-5px);
  }

  64% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}


.box {
  /* border: 1px solid red; */
  animation-duration: 2s;
  animation-iteration-count: infinite;
  /* margin: 0 auto 0 auto; */
  transform-origin: bottom;
  display: flex;
  align-items: center;
}

.bounce-5 {
  animation-name: bounce-5;
  animation-timing-function: ease;
}

.contour {
  /* border: 1px solid red; */
  position: relative;
  top: -8%;
  left: -8%;
  -webkit-animation: spin 120s linear infinite;
  -moz-animation: spin 120s linear infinite;
  animation: spin 120s linear infinite;
  /* animation-timing-function: ease; */

}

.shapes {
  /* border: 1px solid red; */
  width: 80%;
  height: 80%;
  -webkit-animation: spin 120s linear infinite;
  -moz-animation: spin 120s linear infinite;
  animation: spin-reverse 45s linear infinite;

}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spin-reverse {
  100% {
    -moz-transform: rotate(-360deg);
  }
}

@-webkit-keyframes spin-reverse {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}

@keyframes spin-reverse {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}